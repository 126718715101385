import React, { Component } from 'react';
import Header from '../../../components/Header';
import { withRouter } from 'react-router-dom';
import { days } from '../../../constants/date';
import {
	setAccountExercices,
	setGlobalAccountAmounts,
} from '../../../action_creators/accountActionCreators';
import { connect } from 'react-redux';
import { responseManagment } from '../../../functions/ajax';

class EPayment extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	goBack() {
		this.props.history.replace(`/lease/${this.props.match.params.id}/payments/`);
	}

	getDates() {
		const date = new Date();
		const dates = [];
		for (let i = 0; i < 5; i++) {
			dates.push({
				day: days[date.getDay()],
				date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
			});
			date.setDate(date.getDate() + 1);
		}

		return dates;
	}

	render() {
		return (
			<>
				<Header title="Mes paiements" />
				<div className="page-title">
					<button className="btn back-btn" onClick={this.goBack}>
						<img
							src="/images/pictogrammes/back-arrow-blue.svg"
							alt="retour"
						/>
					</button>
					<h2>Payer par e-paiement</h2>
				</div>
				<div className="epayment">
					<div className="btn epayment-btn">
						<button className="btn main-btn">
							Régler mon solde de{' '}
							{Math.abs(this.props.account.global_amounts.solde)} €
						</button>
						<button className="btn main-btn">
							Régler un document en particulier
						</button>
						<button className="btn main-btn">
							Régler un montant en particulier
						</button>
					</div>
					<div className="segment amount">
						<h4>Montant à régler</h4>
						<input
							type="number"
							defaultValue={Math.abs(
								this.props.account.global_amounts.solde
							)}
						/>
					</div>
					<div className="segment dates">
						<h4>Date d'échéance</h4>
						<div className="date-wrapper">
							{this.getDates().map(({ day, date }, index) => (
								<button key={index} className="date">
									<h5>{day}</h5>
									<span>{date}</span>
								</button>
							))}
						</div>
					</div>
					<div className="segment banking-info">
						<h4>Informations bancaires</h4>
						<p>
							Vous avez un mandat de E-paiement à la demande en cours, vous
							pouvez l'utiliser ou en signer un nouveau.
						</p>
						<div className="content">
							<div className="user-info">NOM</div>
							<div className="btn-wrapper">
								<button className="btn main-btn">
									Créer un nouveau mandat
								</button>
								<button className="btn paid-btn">Payer</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		account: state.account,
		user: state.user,
	};
};

const mapDispacthToProps = {
	setAccountExercices,
	setGlobalAccountAmounts,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(EPayment));
