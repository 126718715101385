// Account
export const SET_GLOBAL_ACCOUNT_AMOUNTS = 'SET_GLOBAL_ACCOUNT_AMOUNTS';
export const SET_ACCOUNT_EXERCICES = 'SET_ACCOUNT_EXERCICES';
export const SET_ACCOUNT_CURRENT_EXERCICE = 'SET_ACCOUNT_CURRENT_EXERCICE';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_ACCOUNT_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS';
export const SET_ACCOUNT_PAYMENTS = 'SET_ACCOUNT_PAYMENTS';
export const SET_SHORT_PAYMENTS = 'SET_SHORT_PAYMENTS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
// Debit Mandate
export const SET_MANDATE = 'SET_MANDATE';
// Reminders
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_SHORT_REMINDERS = 'SET_SHORT_REMINDERS';
export const SET_REMINDERS_EXERCICES = 'SET_REMINDERS_EXERCICES';
export const SET_REMINDERS_CURRENT_EXERCICE = 'SET_REMINDERS_CURRENT_EXERCICE';
export const REMOVE_REMINDERS = 'REMOVE_REMINDERS';
// Calls
export const SET_CALLS = 'SET_CALLS';
export const SET_SHORT_CALLS = 'SET_SHORT_CALLS';
export const SET_CALLS_EXERCICES = 'SET_CALLS_EXERCICES';
export const SET_CALLS_CURRENT_EXERCICE = 'SET_CALLS_CURRENT_EXERCICE';
export const REMOVE_CALLS = 'REMOVE_CALLS';

