// module
import React from "react";
// components
import PictoTable from "../PictoTable";

const TableMandate = (props) => (
    <table className={`table horizontal table-mandate`}>
        <thead>
            <tr>
                <th>Créancier</th>
                <th>Débiteur</th>
                <th>Date de début</th>
                <th>Document</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{props.table.creancier}</td>
                <td>{props.table.debiteur}</td>
                <td>{props.table.start}</td>
                <td>
                    {props.table.files.length
                        ? props.table.files.map((file, i) => (
                              <PictoTable key={i} file={file} />
                          ))
                        : " - "}
                </td>
            </tr>
        </tbody>
    </table>
);

export default TableMandate;
