import * as types from '../action_types/accountActionTypes';

// ACCOUNT
export const removeAccount = () => ({
	type: types.REMOVE_ACCOUNT,
});
export const setGlobalAccountAmounts = amounts => ({
	type: types.SET_GLOBAL_ACCOUNT_AMOUNTS,
	amounts,
});
export const setAccountExercices = exercices => ({
	type: types.SET_ACCOUNT_EXERCICES,
	exercices,
});
export const setAccountCurrentExercice = exercice => ({
	type: types.SET_ACCOUNT_CURRENT_EXERCICE,
	exercice,
});
export const setAccountTransactions = transactions => ({
	type: types.SET_ACCOUNT_TRANSACTIONS,
	transactions,
});
export const setAccountPayments = payments => ({
	type: types.SET_ACCOUNT_PAYMENTS,
	payments,
});
export const setShortPayments = payments => ({
	type: types.SET_SHORT_PAYMENTS,
	payments,
});
export const setAccountNumber = number => ({
	type: types.SET_ACCOUNT_NUMBER,
	number,
});
// DEBIT MANDATE
export const setMandate = mandate => ({
	type: types.SET_MANDATE,
	mandate,
});
// REMINDERS
export const setReminders = reminders => ({
	type: types.SET_REMINDERS,
	reminders,
});
export const setShortReminders = reminders => ({
	type: types.SET_SHORT_REMINDERS,
	reminders,
});
export const setRemindersExercices = exercices => ({
	type: types.SET_REMINDERS_EXERCICES,
	exercices,
});
export const setRemindersCurrentExercice = exercice => ({
	type: types.SET_REMINDERS_CURRENT_EXERCICE,
	exercice,
});
export const removeReminders = () => ({
	type: types.REMOVE_REMINDERS,
});
// CALLS
export const setCalls = calls => ({
	type: types.SET_CALLS,
	calls,
});
export const setShortCalls = calls => ({
	type: types.SET_SHORT_CALLS,
	calls,
});
export const setCallsExercices = exercices => ({
	type: types.SET_CALLS_EXERCICES,
	exercices,
});
export const setCallsCurrentExercice = exercice => ({
	type: types.SET_CALLS_CURRENT_EXERCICE,
	exercice,
});
export const removeCalls = () => ({
	type: types.REMOVE_CALLS,
});
