//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

class Header extends Component {
	render() {
		const { title, incident } = this.props;
		let user_menu_hover;

		return (
			<header>
				<div className="header-title">
					<h1>{title}</h1>
					{incident && (
						<NavLink
							to={`/lease/${this.props.match.params.id}/incidents/report`}
							state={{ from: '/incidents' }}
							className="btn main-btn"
						>
							Déclarer un incident
						</NavLink>
					)}
				</div>
				<div className="menu-top">
					{/* contact_link */}
					<div className="user-menu">
						{Object.keys(this.props.user).length ? (
							<h1 className="user-menu-title">
								{this.props.user.first_name
									? `${this.props.user.first_name} ${this.props.user.name[0]}.`
									: this.props.user.name}
							</h1>
						) : null}
						{user_menu_hover}
					</div>
				</div>
			</header>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
	};
};

export default withRouter(connect(mapStateToProps)(Header));
