// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import FormHeater from '../../components/form/FormHeater';
//action
import { setLog } from '../../action_creators/logActionCreators';
// functions
import {
	postRequest,
	responseManagment,
	responseBlobManagment,
} from '../../functions/ajax';
import Header from '../../components/Header';

class HeaterPage extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_put_heater: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
		this.putHeater = this.putHeater.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async putHeater(value) {
		this.setState({ is_loading_put_heater: true });

		const data_to_put = {
			start: `${value.start.year}-${value.start.month}-${value.start.day}`,
			end: `${value.end.year}-${value.end.month}-${value.end.day}`,
		};
		if (value.files.length) {
			data_to_put.file = value.files[0];
		}

		// Update heater
		const response = await postRequest(
			`/heater?activity=${this.props.leases.current.activity}`
				+ `&lease=${this.props.leases.current.id}`,
			data_to_put,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: "Le contrat d'entretien a bien été renouvellée.",
			});
			this.getLeaseDetails();
		}

		if (this._isMounted) {
			this.setState({
				is_loading_put_heater: false,
			});
		}
	}

	goBack() {
		this.props.history.replace(`/lease/${this.props.match.params.id}/lease/`);
	}

	render() {
		return (
			<div>
				<Header title="Tableau de bord" />
				<div className="page-title">
					<button className="btn back-btn" onClick={this.goBack}>
						<img
							src="/images/pictogrammes/back-arrow-blue.svg"
							alt="retour"
						/>
					</button>
					<h2>Renouveler votre contrat d'entretien</h2>
				</div>
				<div className="segment heater">
					<FormHeater
						heater={this.props.leases.current.heater || {}}
						callback={this.putHeater}
						setLog={log => this.props.setLog(log)}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
	};
};
const mapDispacthToProps = {
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(HeaterPage));
