// module
import React, { Component } from "react";
import PropTypes from "prop-types";

export function colorOddRows(k) {
    // Set backgroundcolor to odd rows
    if (k === undefined || k % 2 === 0) return;
    return "tr-colored";
}
export function colorEvenRows(k) {
    // Set backgroundcolor to even rows
    if (k === undefined || k % 2 === 1) return;
    return "tr-colored";
}

export function TableBase(props) {
    return (
        <table className={`table horizontal ${props.className}`}>
            <thead>
                <tr>
                    {props.cols.map((col, i) => (
                        <th key={i}>{col}</th>
                    ))}
                </tr>
            </thead>
            {props.children}
        </table>
    );
}

class Table extends Component {
    displayBoolean(value) {
        return value ? (
            <span className="highlight">{this.props.boolean[1]}</span>
        ) : (
            this.props.boolean[0]
        );
    }

    selectElementsDisplayedValue(value) {
        switch (typeof value) {
            case "boolean":
                return this.displayBoolean(value);

            case "string":
            case "number":
                return value;

            default:
                return null;
        }
    }

    render() {
        return (
            <TableBase className={this.props.className} cols={this.props.cols}>
                <tbody>
                    {this.props.table.map((el, i) => (
                        <tr
                            key={i}
                            className={
                                this.props.cols.length
                                    ? colorOddRows(i)
                                    : colorEvenRows(i)
                            }
                        >
                            {this.props.keys.map((key, i) => (
                                <td
                                    key={i}
                                    className={
                                        key === "label" ? "td-label" : ""
                                    }
                                >
                                    {this.selectElementsDisplayedValue(el[key])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </TableBase>
        );
    }
}

TableBase.propTypes = {
    cols: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
};

Table.propTypes = {
    table: PropTypes.arrayOf(PropTypes.object).isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    cols: PropTypes.arrayOf(PropTypes.string).isRequired,
    boolean: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
};

Table.defaultProps = {
    boolean: ["Non", "Oui"],
    className: "",
};

export default Table;
