// module
import React from "react";
import { Link } from "react-router-dom";

const Lease = ({ lease }) => (
    <>
        <div className={`lease-teaser`}>
            <h2>
                <Link
                    to={`/lease/${lease.id}/dashboard`}
                    className="extended-link"
                >
                    {lease.label}
                </Link>
            </h2>
            <div className="lease-nb-lot">
                <span>Nombre de lots loués : {lease.nb_lots}</span>
            </div>
        </div>
    </>
);

export default Lease;
