import React from 'react';
import { formatNumbers } from '../../functions';

const TableRent = props => {
	const { rent } = props.table;
	const rentTvaAmount = rent.value * (rent.tva_rent / 100);
	const rentTvaCharges =
		rent.charges_provisions * (rent.tva_charges_provisions / 100);
	return (
		<table className="table table-rent">
			{props.table.rent ? (
				<tbody className="reverse-color">
					<tr className="tr-colored">
						<th>Loyer TTC</th>
						<td>
							{formatNumbers(Number(rent.value) + rentTvaAmount)}
							&nbsp;€{' '}
							{rentTvaAmount
								? `(dont TVA ${formatNumbers(rentTvaAmount)} €)`
								: null}
						</td>
					</tr>
					<tr>
						<th>Provisions pour charges TTC</th>
						<td>
							{formatNumbers(
								Number(rent.charges_provisions) + rentTvaCharges
							)}
							&nbsp;€{' '}
							{rentTvaCharges
								? `(dont TVA ${formatNumbers(rentTvaCharges)} €)`
								: null}
						</td>
					</tr>
					<tr className="tr-colored">
						<th>Dépôt de garantie</th>
						<td>
							{formatNumbers(rent.security_deposits)}
							&nbsp;€
						</td>
					</tr>
					<tr>
						<th>Periodicité</th>
						<td>{rent.periodicity}</td>
					</tr>
					<tr className="tr-colored">
						<th>Prochain appel</th>
						<td>{props.table.next_call}</td>
					</tr>
				</tbody>
			) : null}
		</table>
	);
};

export default TableRent;
