// module
import React from 'react';
import PropTypes from 'prop-types';

function FormItem(props) {
    let name = props.input.name;
    let type = props.input.type;
    let label = props.input.label;
    let placeholder = props.input.placeholder;
    let required = props.input.required;

    return (
      <div className="form-item form-group">
        <label htmlFor={name}>
          {/* Add '*' to required input's label */}
          {label}
          {required ? <span>*</span> : ""}
        </label>
        {props.input.type === "textarea" ? (
          <textarea
            name={name}
            id={name}
            onChange={(evt) => props.callback(evt)}
            value={props.value}
            required={required}
          />
        ) : (
          <input
            type={type}
            name={name}
            id={name}
            onChange={(evt) => props.callback(evt)}
            value={props.value}
            placeholder={placeholder}
            required={required}
          />
        )}
      </div>
    );
}

FormItem.propTypes = {
    input: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    value: PropTypes.any
};

export default FormItem;