import * as types from '../action_types/filesActionTypes';

const initialState = {
	label: 'GÉNÉRAL',
	id: 0,
	folders: [],
	files: [],
};

function filesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.RESET_FOLDERS:
			return initialState;

		case types.SET_FOLDERS:
			nextState = { ...state, ...action.folder };
			return nextState;

		case types.UPDATE_FOLDER:
			nextState = { ...state };
			// 	if (Array.isArray(action.indexes)) {
			// 		updateRecursiveFolders(nextState, action);
			// 	} else {
			nextState.folders[action.index] = {
				...nextState.folders[action.index],
				...action.value,
			};
			// 	}
			return nextState;

		default:
			return state;
	}
}

// function updateRecursiveFolders(state, action) {
// 	let currentFolder = state;
// 	let lastIndex = 0;
// 	action.indexes.forEach(index => {
// 		currentFolder.folders = currentFolder.folders.slice();
// 		currentFolder = { ...currentFolder.folders[index] };
// 		lastIndex = index;
// 	});
// 	currentFolder.folders[lastIndex] = action.value;
// }

export default filesReducer;
