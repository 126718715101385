// module
import React, { Component } from 'react';
// components
import { TableBase, colorOddRows } from './Table';
import { formatNumbers } from '../../functions';

class TablePayments extends Component {
	render() {
		return (
			<TableBase className="table-payment" cols={this.props.cols}>
				<tbody className="reverse-color">
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td>{el.date}</td>
							<td className="td-label">{el.label}</td>
							<td>{formatNumbers(el.credit - el.debit)}&nbsp;€</td>
							<td className="text-green">Effectué</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

export default TablePayments;
