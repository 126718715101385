import React, { Component } from 'react';

class FileToggleButton extends Component {
	render() {
		return (
			<div className="file-toggle">
				<button
					className={`file-table ${
						this.props.layout === 'table' ? 'active' : ''
					}`}
					onClick={() => this.props.onClick('table')}
				>
					{this.props.layout === 'table' && (
						<img
							src="/images/pictogrammes/checked-white.svg"
							className="profile-img img-fluid"
							alt="Check"
						/>
					)}
					<img
						src="/images/pictogrammes/bars-white.svg"
						className="profile-img img-fluid"
						alt="Bars"
					/>
				</button>
				<button
					className={`file-list ${
						this.props.layout === 'grid' ? 'active' : ''
					}`}
					onClick={() => this.props.onClick('grid')}
				>
					{this.props.layout === 'grid' && (
						<img
							src="/images/pictogrammes/checked-white.svg"
							className="profile-img img-fluid"
							alt="Check"
						/>
					)}
					<img
						src="/images/pictogrammes/table-cells-white.svg"
						className="profile-img img-fluid"
						alt="Table cells"
					/>
				</button>
			</div>
		);
	}
}

export default FileToggleButton;
