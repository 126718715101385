// module
import React, { Fragment } from 'react';
// components
import PictoTable from '../PictoTable';

const TableLease = props => (
	<table className="table table-lease">
		<tbody className="reverse-color">
			<tr className="tr-colored">
				<th>Libellé</th>
				<td>{props.table.label}</td>
			</tr>
			<tr>
				<th>Début - Fin</th>
				<td>
					{props.table.start} - {props.table.end}
				</td>
			</tr>
			<tr className="tr-colored">
				<th>Mon bail PDF</th>
				<td>
					{props.table.files.length
						? props.table.files.map((file, i) => (
								<div key={i} className="lease-file">
									<PictoTable key={i} file={file} />
									<span>Bail PDF</span>
								</div>
						  ))
						: ' - '}
				</td>
			</tr>
			<tr>
				<th>État des lieux</th>
				<td>
					{!props.table.inventory_entry.length
					&& !props.table.inventory_exit.length ? (
						' - '
					) : (
						<>
							{!!props.table.inventory_entry.length
								&& props.table.inventory_entry.map((file, i) => (
									<Fragment key={i}>
										<PictoTable file={file} />
										<p>État des lieux entrant</p>
									</Fragment>
								))}
							{!!props.table.inventory_exit.length
								&& props.table.inventory_exit.map((file, i) => (
									<PictoTable key={i} file={file} />
								))}
						</>
					)}
				</td>
			</tr>
			<tr className="tr-colored">
				<th>Mon assurance</th>
				{props.table.insurance ? (
					<td>
						{props.table.insurance.files.map((file, i) => (
							<PictoTable key={i} file={file} />
						))}
						<span className="mr-3">Assurance</span>
						<span className="font-weight-bold">Début-Fin</span>
						{props.table.insurance.start
							? props.table.insurance.start
							: '?'}{' '}
						- {props.table.insurance.end ? props.table.insurance.end : '?'}
					</td>
				) : (
					<td>-</td>
				)}
			</tr>
			<tr>
				<th>Mes contrats</th>
				{props.table.heater ? (
					<td>
						{props.table.heater.files.map((file, i) => (
							<PictoTable key={i} file={file} />
						))}
						<span className="mr-3">Mon contrat</span>
						<span className="font-weight-bold">Début-Fin</span>
						{props.table.heater.start ? props.table.heater.start : '?'} -{' '}
						{props.table.heater.end ? props.table.heater.end : '?'}
					</td>
				) : (
					<td>-</td>
				)}
			</tr>
		</tbody>
	</table>
);

export default TableLease;
