//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Loading from '../../components/Loading';
import FormIncident from '../../components/form/FormIncident';
//action
import { removeIncidents } from '../../action_creators/incidentsActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { postRequest, responseManagment } from '../../functions/ajax';
import Header from '../../components/Header';

const form_items = [
	{
		type: 'text',
		name: 'label',
		label: `Libellé`,
		required: true,
	},
	{
		type: 'textarea',
		name: 'details',
		label: `Détails`,
		required: true,
	},
];

class ReportIncident extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.postIncident = this.postIncident.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async postIncident(value) {
		this.setState({ is_loading: true });

		let data_to_post = {
			type: value.label,
			description: value.details,
		};
		data_to_post.file1 = value.files[0];
		data_to_post.file2 = value.files[1];

		// Post incidents
		const response = await postRequest(
			`/incident-add?activity=${this.props.leases.current.activity}`,
			data_to_post,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: "L'incident a bien été enregistré.",
			});
			// Update incidents to see new incident
			this.props.removeIncidents();
		}

		if (this._isMounted) {
			this.setState({
				is_loading: false,
			});
		}
	}

	goBack() {
		if (this.props.history.length < 3) {
			this.props.history.replace(`/lease/${this.props.match.params.id}/incidents/`);
		} else {
			this.props.history.goBack();
		}
	}

	render() {
		return (
			<div>
				<Header title="Incidents" />
				<div className="page-title">
					<button className="btn back-btn" onClick={this.goBack}>
						<img
							src="/images/pictogrammes/back-arrow-blue.svg"
							alt="retour"
						/>
					</button>
					<h2>Déclarer un incident</h2>
				</div>
				<div className="segment">
					{this.state.is_loading === false ? (
						<FormIncident
							form_items={form_items}
							callback={this.postIncident}
							setLog={log => this.props.setLog(log)}
							label_btn="Déclarer"
						/>
					) : (
						<Loading />
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
	};
};
const mapDispacthToProps = {
	removeIncidents,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(ReportIncident));
