import React from 'react';
// component
import Form from './Form';
import PasswordField from './item/PasswordField';

class FormPassword extends Form {
	constructor(props) {
		super(props);

		this.state = {
			new_password: '',
		};
	}

	handleNewPassword(evt) {
		this.setState({ new_password: evt.target.value });
	}

	handleChange(evt) {
		super.handleChange(evt);

		if (evt.target.name === 'confirmation') {
			this.checkSameness(evt);
		}
	}

	// Check if new_password === confirmation, if not block submit and inform the user
	checkSameness(evt) {
		if (this.state.new_password !== evt.target.value) {
			evt.target.setCustomValidity(
				"La confirmation de mot de passe n'est pas identique au nouveau mot de passe."
			);
		} else {
			evt.target.setCustomValidity('');
		}
	}

	render() {
		let form_item = this.buildFormItems();
		return (
			<form className="form" onSubmit={this.handleSubmit}>
				<div className="form-item-list">{form_item[0]}</div>
				<div className="form-item-list">
					<PasswordField
						type="password"
						name="new_password"
						label="Nouveau mot de passe"
						placeholder="Entrez votre nouveau mot de passe"
						required
						callback={(evt) => this.setState({ new_password: evt.target.value })}
					/>
					<p>
						{
							'Le mot de passe doit faire au moins 8 caractères, contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial : !?@#%$£€¤§&µ*^°=+;<>'
						}
					</p>
				</div>
				<div className="form-item-list">{form_item[1]}</div>
				<div className="submit-button-wrapper">
					<button className="btn submit-button main-btn" type="submit">
						Valider
					</button>
				</div>
			</form>
		);
	}
}

export default FormPassword;
