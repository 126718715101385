//actions
import {
	removeAccount,
	removeReminders,
	removeCalls,
} from '../action_creators/accountActionCreators';
import { resetFolders } from '../action_creators/filesActionCreators';
import { removeIncidentsAndFilters } from '../action_creators/incidentsActionCreators';
import { removeLots } from '../action_creators/lotsActionCreators';
import { removeNotifications } from '../action_creators/notificationsActionCreators';
import { removeAdmin } from '../action_creators/adminActionCreators';

export default {
	removeAccount,
	removeReminders,
	removeCalls,
	removeAdmin,
	removeIncidentsAndFilters,
	removeLots,
	removeNotifications,
	resetFolders,
};
