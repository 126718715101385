// module
import React from 'react';
// components
import PropTypes from 'prop-types';
// function

const TableAdmin = props => (
	<table className="table table-admin">
		<tbody className="reverse-color">
			<tr className="tr-colored">
				<th>Nom</th>
				<td>{props.table.name}</td>
			</tr>
			<tr>
				<th>Adresse</th>
				<td>{props.table.address.full}</td>
			</tr>
			<tr className="tr-colored">
				<th>Contact</th>
				<td>
					<a href={`mailto:${props.table.email}`}>{props.table.email}</a>
				</td>
			</tr>
			<tr>
				<th>Téléphone</th>
				<td>{props.table.phone}</td>
			</tr>
		</tbody>
	</table>
);

TableAdmin.propTypes = {
	table: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default TableAdmin;
