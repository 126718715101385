import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { API_PDF_SRC_URL } from '../../constants/api';
import Loading from '../Loading';

class GridFile extends Component {
	render() {
		const { folders, files } = this.props.folder;
		const { is_loading } = this.props;
		if (is_loading) {
			return <Loading />;
		}
		if (!folders?.length && !files?.length) {
			return <p>Ce dossier est vide.</p>;
		}
		return (
			<div className="folder-content">
				{folders?.length
					? folders.map((folder, i) => (
							<div
								key={i}
								className="folder"
								onClick={e => this.props.handleFolder(i)}
							>
								<img
									src={`/images/pictogrammes/folder-lightblue.svg`}
									alt={`dossier ${folder.label}`}
									className="picto-folder"
								/>
								<p className={'folder-name'}>{folder.label}</p>
							</div>
					  ))
					: null}
				{files?.length
					? files.map((file, i) => (
							<div key={i} className="file">
								<img
									src={`/images/pictogrammes/file-lightblue.svg`}
									alt={`télécharger le fichier ${file.label}`}
									className="picto-file"
								/>
								<a
									href={API_PDF_SRC_URL + file.url}
									className="file-name"
								>
									{file.label}
								</a>
							</div>
					  ))
					: null}
			</div>
		);
	}
}
export default withRouter(GridFile);
