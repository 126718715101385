// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { browserName, browserVersion } from 'react-device-detect';
// component
import Page from './class/containers/router/Page';
import Login from './class/containers/login/Login';
import ForgottenPassword from './class/containers/login/ForgottenPassword';
import LegalNotice from './class/containers/LegalNotice';
import EdgeMessage from './class/components/EdgeMessage';
import Log from './class/components/Log';
//action
import {
	setUser,
	setToken,
	setUserProfilePicture,
} from './class/action_creators/userActionCreators';
import { setLeases } from './class/action_creators/leasesActionCreators';
import { setLog } from './class/action_creators/logActionCreators';
// functions
import {
	getRequest,
	responseManagment,
	responseBlobManagment,
} from './class/functions/ajax';
import { getLeases } from './class/functions/leases';
import { getProfilePicture } from './class/functions/user';
// constants
import { STORED_USER, STORED_LEASES } from './class/constants/localStorage';
import LegalNoticePage from './class/containers/LegalNoticePage';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			timer: null,
			no_user_stored: false,
			edge_message_visible: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
		this.getLeases = getLeases.bind(this);
		this.getProfilePicture = getProfilePicture.bind(this);
		this.refreshToken = this.refreshToken.bind(this);
		this.closeEdgeMessage = this.closeEdgeMessage.bind(this);
	}

	async UNSAFE_componentWillMount() {
		if (browserName === 'Edge' && browserVersion < 79) {
			this.setState({ edge_message_visible: true });
		}

		let user;
		let leases;

		if (
			!Object.keys(this.props.user).length
			&& this.props.location.pathname !== '/login'
			&& this.props.location.pathname !== '/logout'
			&& this.props.location.pathname.slice(0, 7) !== '/token/'
		) {
			// Gets user from local storage so from previous sessions
			user = JSON.parse(localStorage.getItem(STORED_USER));
			// Gets leases from local storage so from previous sessions
			leases = JSON.parse(localStorage.getItem(STORED_LEASES));
			if (user && user.name) {
				// Stores user if it is
				this.props.setUser(user);
				// Gets profile picture because it blob cannot be in local storage, it's a temporary file
				this.getProfilePicture(user.token);
				// Gets leases from API if there are not in local storage
				if (!leases) {
					leases = await this.getLeases(user.token);
					if (!Object.keys(leases).length) {
						// This user haven't leases
						this.props.history.replace('/');
					}
				}
				// Stores lease from local storage or from API
				this.props.setLeases(leases);
				// Checks if stored token is still valid and get a newer with it to respect timer
				this.refreshToken(user.token);
			} else {
				// There is no user in local storage so user should connect it self
				this.setState({ no_user_stored: true });
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// If there is a new token
		if (this.props.user.token && prevProps.user.token !== this.props.user.token) {
			// Updates stored token with it
			let user = { ...this.props.user };
			// Doesn't store profile picture blob , it's a temporary file
			delete user.profile_picture;
			localStorage.setItem(STORED_USER, JSON.stringify(user));
			// Resets timer
			this.setTimerToRefreshToken();
		}
	}

	setTimerToRefreshToken() {
		// Stops timer if token has change beetween the 30 min, to relaunch it
		clearTimeout(this.state.timer);
		// Refreshs token in 30 min
		let timer = setTimeout(this.refreshToken, 1800000);
		this.setState({ timer });
	}

	// Used current token or given token (not already in store) to get a newer. This will automatically reset timer
	async refreshToken(token = this.props.user.token) {
		// Asks a new token with a token
		const response = await getRequest(`/check-token`, token);
		if (this.responseManagment(response)) {
			// Stores new token
			this.props.setToken(response.token);
		} else {
			// Stops timer if token is invalid
			clearTimeout(this.state.timer);
		}
	}

	closeEdgeMessage(evt) {
		this.setState({ edge_message_visible: false });
	}

	render() {
		let app_class = 'app';
		if (!this.props.user.token) {
			// Login and Logout pages should style logs a bit diffrently
			app_class += ' not-logged-page';
		}
		if (this.state.edge_message_visible) {
			app_class += ' wrap-edge-message';
		}

		return (
			<div className={app_class}>
				{this.props.log.message ? <Log /> : null}
				{this.state.edge_message_visible && (
					<EdgeMessage closeEdgeMessage={this.closeEdgeMessage} />
				)}
				{/* Only connected user has access to the rest of the app */}
				<Switch>
					<Route exact path="/login">
						<Login />
					</Route>
					<Route exact path="/legal-notice">
						<LegalNoticePage />
					</Route>
					<Route exact path="/forgotten-password">
						<ForgottenPassword />
					</Route>
					<Route exact path="/token/:token">
						<Login />
					</Route>
					{/* Redirection */}
					<Route path="/logout">
						<Redirect to={`/login`} />
					</Route>
					{this.props.user.token ? (
						// If user, page manage the other routes
						<Route path="/">
							<Page />
						</Route>
					) : (
						// Redirection after checking stored user
						this.state.no_user_stored === true && (
							<Route path="/">
								<Redirect to={`/login`} />
							</Route>
						)
					)}
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
		log: state.log,
	};
};

const mapDispatchToProps = {
	setUser,
	setToken,
	setLeases,
	setUserProfilePicture,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
