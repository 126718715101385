// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// component
import Form from '../../../components/form/Form';
import Loading from '../../../components/Loading';
// actions
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { postRequest, responseManagment } from '../../../functions/ajax';
import Contact from './Contact';
import Header from '../../../components/Header';

const form_items = [
	{
		type: 'text',
		name: 'object',
		label: `Objet`,
		required: true,
	},
	{
		type: 'textarea',
		name: 'message',
		label: `Message`,
	},
];

class Help extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.postMessage = this.postMessage.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async postMessage(form_value) {
		this.setState({ is_loading: true });
		const response = await postRequest(
			'/message-client',
			form_value,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message:
					'Message envoyé avec succès. Nous vous répondrons dans les plus bref délais.',
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	render() {
		return (
			<>
				<Header title="Aide & Contact" />
				<div className="contact">
					<div className="help-header">
						<p>
							Pour vous aider à naviguer sur votre espace locataire, vous
							pouvez télécharger notre guide.
						</p>
						<a
							className="btn main-btn download-btn"
							href={`/pdf/MY${
								!!this.props.leases.current
								&& this.props.leases.current.sousTraitance === true
									? 'GP'
									: ''
							}-Guide-Locataire.pdf`}
							// eslint-disable-next-line react/jsx-no-target-blank
							target="_blank"
							rel="noreferrer"
						>
							Télécharger le guide
						</a>
					</div>
					<div className="contact-forms">
						{this.props.leases.current && <Contact />}
						<div className="segment">
							<h3>Un problème technique ?</h3>
							<p className="mb-3">Merci de nous écrire ici :</p>
							{this.state.is_loading === false ? (
								<Form
									form_items={form_items}
									callback={this.postMessage}
									button_label="Envoyer"
								/>
							) : (
								<Loading />
							)}
						</div>
					</div>
					<div className="legal-links">
						<Link to="/help/legal-notice">Mentions Légales</Link>
						<a
							href="/pdf/CGU-MonEspaceLocataire.pdf"
							// eslint-disable-next-line react/jsx-no-target-blank
							target="_blank"
						>
							Conditions Générales d'Utilisation
						</a>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ user, leases }) => ({ user, leases });
const mapDispatchToProps = { setLog };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Help));
