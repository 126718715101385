import React, { Component } from 'react';
import Header from '../../../components/Header';
import { postRequest, responseManagment } from '../../../functions/ajax';
import { setLog } from '../../../action_creators/logActionCreators';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileItem from '../../../components/form/item/FileItem';

class DirectDebit extends Component {
	_isMounted = false;

	initialMessage = {
		object: `Mail automatique - Demande de prélèvement pour le bail ${this.props.leases.current.label} de ${this.props.user.first_name} ${this.props.user.name}`,
		message: `Bonjour, je souhaiterais souscrire à un prélèvement automatique pour le règlement de mon loyer. Merci de m'envoyer un mandat de prélèvement.\r\n Voici mon RIB en pièce jointe.\r\n\r\nBonne journée !\r\n\r\nCordialement,\r\n\r\n${this.props.user.first_name} ${this.props.user.name}.`,
	};

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			message: this.initialMessage,
			is_sending: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleChangeFiles = this.handleChangeFiles.bind(this);
		this.postMessage = this.postMessage.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	async postMessage(evt) {
		evt.preventDefault();
		if (!this.state.message.files) {
			return;
		}
		this.setState({ is_sending: true });
		const response = await postRequest(
			`/message-gestionnaire?activity=${this.props.leases.current.activity}`,
			this.state.message,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: 'Message envoyé au gestionnaire avec succès.',
			});
		}

		this.setState({
			message: this.initialMessage,
		});

		if (this._isMounted) {
			this.setState({ is_sending: false });
		}
	}

	handleChangeFiles(files) {
		this.setState({ message: { files, ...this.state.message } });
	}

	goBack() {
		this.props.history.replace(`/lease/${this.props.match.params.id}/payments/`);
	}

	render() {
		return (
			<>
				<Header title="Mes paiements" />
				<div className="direct-debit">
					<div className="page-title">
						<button className="btn back-btn" onClick={this.goBack}>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Payer par prélèvement automatique</h2>
					</div>
					<div className="segment">
						<p className="text-rib">
							Pour mettre en place un paiement par prélèvement automatique
							avec votre gestionnaire, merci de déposer votre RIB ci-dessous
							afin que nous le communiquions à la bonne personne ! Vous
							recevrez par la suite un mandat à signer électroniquement
						</p>
						<form className="form" onSubmit={this.postMessage}>
							<div className="form-item-list">
								<div className="form-item-file-wrapper">
									<FileItem
										name="file-insurance"
										title="PDF"
										label="Déposez votre RIB"
										label_btn="Glisser / Déposer"
										type="pdf"
										nb_max={1}
										max_Mo={10}
										callback={this.handleChangeFiles}
										setLog={log => this.props.setLog(log)}
									/>
									<p className="file-name">
										{this.state.message.files?.[0]?.name}
									</p>
									<div className="submit-button-wrapper">
										<button
											className="btn submit-button main-btn"
											type="submit"
										>
											Valider
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
	};
};
const mapDispacthToProps = {
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(DirectDebit));
