import React, { Component } from 'react';
import FormItem from './FormItem';


class PasswordField extends Component {
	colors = ['', 'red', 'orange', 'green'];
	passwordRegex = new RegExp(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#%$£€¤§&µ*^°=+;<>]).{8,}$/
	);
	
	constructor(props) {
		super(props);
		
		this.state = {
			security: 0,
		};
		
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(evt) {
		const value = evt.target.value;
		let security = 0;

		if (!!value) {
			security++;
		}

		if (value.length >= 8) {
			security++;
		}

		if (this.passwordRegex.test(value)) {
			security++;
		}
		this.setState({ security });

		this.props.callback(evt);
	}

	render() {
		return (
			<>
				<FormItem
					input={this.props}
					value={this.props.value}
					callback={this.handleChange}
				/>
				<div className="password-strength">
					<div
						className={`security ${
							this.state.security > 0
								? this.colors[this.state.security]
								: ''
						}`}
					/>
					<div
						className={`security ${
							this.state.security > 1
								? this.colors[this.state.security]
								: ''
						}`}
					/>
					<div
						className={`security ${
							this.state.security > 2
								? this.colors[this.state.security]
								: ''
						}`}
					/>
				</div>
			</>
		);
	}
}

export default PasswordField;
