// module
import React, { Component } from "react";
import PropTypes from "prop-types";
// components
import { TableBase, colorOddRows } from "./Table";
import TdAmount from "./TdAmount";
import FileDownloadedFromAPI from "../FileDownloadedFromAPI";

class TableCalls extends Component {
    render() {
        return (
            <TableBase className="table-calls" cols={this.props.cols}>
                <tbody className="reverse-color">
                    {this.props.table.map((el, i) => (
                        <tr key={i} className={colorOddRows(i)}>
                            <td className="date">{el.date}</td>
                            <TdAmount value={el.amount} />
                            <TdAmount
                                value={el.remaining_amount}
                                negative={true}
                            />
                            {!!this.props.cols[3] && (
                                <td>
                                    {el.receipt === true && (
                                        <FileDownloadedFromAPI
                                            uri={
                                                `/calls-receipt?`
                                                + `activity=${this.props.activity}`
                                                + `&id_call=${el.id}`
                                            }
                                            filename={`quittance-${el.date}-${el.id}`}
                                            className="picto-table-wrapper"
                                        >
                                            <img
                                                src="/images/pictogrammes/file-blue.svg"
                                                alt="Télécharger la quittance"
                                                className={`picto-table`}
                                                title="Quittance de loyer pdf"
                                            />
                                        </FileDownloadedFromAPI>
                                    )}
                                    <FileDownloadedFromAPI
                                        uri={
                                            `/calls-pdf?`
                                            + `activity=${this.props.activity}`
                                            + `&id_call=${el.id}`
                                        }
                                        filename={`appel-loyer-${el.date}-${el.id}`}
                                        className="picto-table-wrapper"
                                    >
                                        <img
                                            src="/images/pictogrammes/file-blue.svg"
                                            alt="Télécharger l'appel de loyer"
                                            className={`picto-table`}
                                            title="Appel de loyer pdf"
                                        />
                                    </FileDownloadedFromAPI>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </TableBase>
        );
    }
}

TableCalls.propTypes = {
    // ...Table.propTypes,
    activity: PropTypes.number.isRequired,
};

export default TableCalls;
