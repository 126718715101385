// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// component
import Lease from '../components/Lease';
import Modal from '../components/Modal';
import Form from '../components/form/Form';
import Loading from '../components/Loading';
//actions
import { removeCurrentLease } from '../action_creators/leasesActionCreators';
import { setUserCGU } from '../action_creators/userActionCreators';
import { setLog } from '../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../functions/ajax';
import Header from '../components/Header';

const form_items = [
	{
		type: 'checkbox',
		name: 'cgu',
		label:
			`J'accepte les\u00A0`
			+ `<a href="https://www.mygestionpro.com/" target="_blank">`
			+ `conditions générales d'utilisation`
			+ `</a>.`,
		required: true,
	},
];

class MyLeases extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			display_cgu_modal: true,
			is_loading: false,
		};

		this.setCGU = this.setCGU.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		// Remove current Lease if it exist
		if (this.props.leases.current) {
			this.props.removeCurrentLease();
		}
		this.checkCGU();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user.cgu !== this.props.user.cgu) {
			this.checkCGU();
		}
	}

	// Don't ask CGU if it has been already done
	checkCGU() {
		if (this.props.user.cgu !== false) {
			this.toggleModal(false);
		}
	}

	async setCGU(value) {
		if (value.cgu) {
			this.setState({ is_loading: true });
			const response = await getRequest(`/user-cgu`, this.props.user.token);
			if (this.responseManagment(response)) {
				this.props.setUserCGU(new Date().toLocaleDateString('fr-FR'));
				this.props.setLog({
					type: 'success',
					message: "Conditions générales d'utilisation acceptées.",
				});
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	toggleModal(is_visible) {
		this.setState({ display_cgu_modal: is_visible });
	}

	render() {
		let leases = Object.entries(this.props.leases.list);
		return (
			<>
				<Header title="Mes locations" />
				<div className="my-leases">
					<div className={'lease-list'}>
						{leases.length ? (
							leases.map(([id, lease], i) => (
								<Lease lease={lease} key={i} />
							))
						) : (
							<p>Vous n'avez aucune location.</p>
						)}
					</div>
					{/* Modal */}
					<Modal
						// This modal couldn't be hide by clicking on overlay
						id="my-lease-modal-cgu"
						is_visible={this.state.display_cgu_modal}
					>
						<h2>Conditions Générales d'Utilisation</h2>
						<p>
							Afin de pouvoir utiliser notre logiciel, merci de bien vouloir
							accepter les conditions générales d'utilisation.
						</p>
						{this.state.is_loading === false ? (
							<Form form_items={form_items} callback={this.setCGU} />
						) : (
							<Loading />
						)}
					</Modal>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
	};
};

const mapDispatchToProps = {
	removeCurrentLease,
	setUserCGU,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyLeases));
